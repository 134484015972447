<template>
  <div>
    <h2>覚醒スキル画像切り出しツール</h2>
    
    <!-- 画像貼り付けエリア -->
    <div class="image-paste-area" @paste="onPaste">
      ここに画像を貼り付けてください
    </div>

    <!-- パラメータ入力フォーム -->
    <div class="parameter-form">
      <label for="x">X座標 (%):</label>
      <input type="number" id="x" v-model.number="coordinates.x" min="0" max="100">

      <label for="y">Y座標 (%):</label>
      <input type="number" id="y" v-model.number="coordinates.y" min="0" max="100">

      <label for="size">サイズ (%):</label>
      <input type="number" id="size" v-model.number="size" min="0" max="100">

      <label for="spacing">間隔 (%):</label>
      <input type="number" id="spacing" v-model.number="spacing" min="0" max="100">
      <button @click="processImage">切り出し</button>
    </div>

    <!-- プレビューキャンバス -->
    <canvas ref="previewCanvasRef" width="200" height="200"></canvas>

    <!-- 切り出し画像表示エリア -->
    <div class="output-images">
      <img v-for="(image, index) in outputImages" :key="index" :src="image" alt="切り出し画像">
    </div>
    <!-- マッチした覚醒スキルID表示エリア -->
    <div class="matched-awakens">
      マッチした覚醒スキル：
      <div v-for="(awakenId, index) in matchedAwakens" :key="index">
        <template v-if="awakenId !== null">
          <awaken-icon :no="awakenId"></awaken-icon>
        </template>
        <template v-else>
          未検出
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultParameters, extractImages, processAwakenMatching } from '@/utils/awknDetector.ts';

export default {
  name: 'PageGetAwakens',
  data() {
    return {
      sourceImage: null,
      coordinates: {
        x: defaultParameters.coordinates.x,
        y: defaultParameters.coordinates.y,
      },
      size: defaultParameters.size,
      spacing: defaultParameters.spacing,
      outputImages: [],
      matchedAwakens: [], // マッチした覚醒IDを格納
    };
  },
  mounted() {
    this.updatePreview();
  },
  watch: {
    coordinates: {
      handler: function(newVal, oldVal) {
        this.updatePreview();
      },
      deep: true
    },
    size: function(newVal, oldVal) {
      this.updatePreview();
    },
    spacing: function(newVal, oldVal) {
      this.updatePreview();
    }
  },
  methods: {
    onPaste(event) {
      const items = (event.clipboardData || event.clipboardData).items;
      let blob = null;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") === 0) {
          blob = items[i].getAsFile();
        }
      }
      if (blob !== null) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.sourceImage = new Image();
          this.sourceImage.onload = () => {
            this.updatePreview();
          }
          this.sourceImage.src = event.target.result;
        };
        reader.readAsDataURL(blob);
      }
    },
    updatePreview() {
      if (!this.sourceImage) return;

      const canvas = this.$refs.previewCanvasRef;
      const ctx = canvas.getContext('2d');

      const imageWidth = this.sourceImage.width;
      const imageHeight = this.sourceImage.height;

      canvas.width = imageWidth;
      canvas.height = imageHeight;

      ctx.drawImage(this.sourceImage, 0, 0, imageWidth, imageHeight);

      const x = this.coordinates.x / 100 * imageWidth;
      const yBase = this.coordinates.y / 100 * imageHeight;
      const size = this.size / 100 * Math.min(imageWidth, imageHeight);
      const spacing = this.spacing / 100 * Math.min(imageWidth, imageHeight);

      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;

      for (let i = 0; i < 9; i++) {
        const y = yBase + i * (size + spacing);
        ctx.strokeRect(x, y, size, size);
      }
    },
    async processImage() {
      if (!this.sourceImage) return;

      const { dataURLs } = extractImages(this.sourceImage, this.coordinates, this.size, this.spacing);
      this.outputImages = dataURLs;

      const matchedAwakens = await processAwakenMatching(
        this.sourceImage,
        this.coordinates,
        this.size,
        this.spacing
      );

      this.matchedAwakens = matchedAwakens;
      console.log("matchedAwakens", this.matchedAwakens);
    }
  }
};
</script>

<style scoped>
.image-paste-area {
  border: 1px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.parameter-form {
  margin-bottom: 20px;
}


.parameter-form label {
  display: inline-block;
  width: 80px;
  text-align: right;
  margin-right: 10px;
}

.parameter-form input {
  width: 50px;
}

.output-images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.output-images img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.matched-awakens {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.matched-awakens > div {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>