import axios, { AxiosResponse } from 'axios';
import store from './store';
import firebase from 'firebase/compat/app';
import { RouteLocationRaw } from 'vue-router';
import { getSrc as getDropIconUrl } from './components/dropIcon.vue';
import { getAwakenIconUrl } from './components/awakenIcon.vue';
import { EditingMonsterData, MonsterData, MonsterDataWithAwakenCount, SkillDetails, EvolutionWithMonsterNo, SkillDetailsWithNo } from './types';

/** このウェブサービス固有の情報関連。 */
export const mtpadmdb = {
  /** サーバーAPI とのやり取りを行う。 */
  api: function (mode: string, params: any, option: any, onSuccess: Function, onError?: Function) {
    if (typeof (option) === 'function') {
      onError = onSuccess;
      onSuccess = option;
      option = undefined;
    }
    // 後で option.header に追加を行うので、そこの処理を単純化するため指定のない場合に空オブジェクトを入れておく。
    if (!option) { option = {}; }
    if (!option.headers) { option.headers = {}; }

    let axiosObj;
    switch (mode) {
    case 'monsterDetails':
      // APIではなくJSONファイルをリクエストする。
      Object.assign(option.headers, {
        'Cache-Control': 'no-cache',
        'Expires': '-1',
        'X-Requested-With': 'XMLHttpRequest'
      });
      axiosObj = axios.get(`./api.cgi/monster?no=${params.no}`, option);
      break;
    case 'monsterHistory':
    case 'monsterHistoryDetails':
    case 'monsterImageHistory':
    case 'skillHistory':
      Object.assign(option.headers, {
        'Cache-Control': 'no-cache',
        'Expires': '-1',
        'X-Requested-With': 'XMLHttpRequest'
      });
      option.params = Object.assign(option.params || {}, params);
      axiosObj = axios.get(`./api.cgi/${mode}`, option);
      break;
    case 'updateMonster':
    case 'image':
    case 'updateSkill':
      Object.assign(option.headers, {
        'X-Requested-With': 'XMLHttpRequest'
      });
      axiosObj = axios.post(`./api.cgi/${mode}`, params, option);
      break;
    default:
      console.log(`mode"${mode}" is not found. by mtpadmdb.api()\n`);
      return;
    }
    const commonWork = (response: AxiosResponse<any, any>) => {
      if (!response.data) { return; }
      if (response.data.newTableData) {
        const newTableData = response.data.newTableData;
        if (newTableData.monster) {
          store.commit('addMonsterData', newTableData.monster);
        }
        if (newTableData.skillDetails) {
          store.commit('addSkillData', newTableData.skillDetails);
        }
        if (newTableData.leaderSkillDetails) {
          store.commit('addLeaderSkillData', newTableData.leaderSkillDetails);
        }
        if (newTableData.imageTable) {
          store.commit('addImageData', newTableData.imageTable);
        }
      }
      store.commit('clearErrors');
      store.commit('clearMessages');
      if (response.data._messages) {
        store.commit('setMessages', response.data._messages);
      }
      if (response.data._errors) {
        store.commit('setErrors', response.data._errors);
      }
    };

    axiosObj.then(response => {
      commonWork(response);
      if (response.data._errors) {
        onError && onError(response);
      } else {
        onSuccess(response);
      }
    }).catch(response => {
      commonWork(response);
      onError && onError(response);
    });
  },
  /** 編集中保存データのリストを取得する。 */
  loadEditingDataList: function () {
    const json = localStorage.getItem('editingData');
    if (json) {
      const loadData = JSON.parse(json);
      if (loadData.version === 1) {
        return loadData.data;
      }
    }
    return {};
  },
  /** 編集中データを保存する。指定されたIDのデータが既に存在する場合は上書きする。 */
  saveEditingData: function (id: number, type?: any, data?: any) {
    const list = this.loadEditingDataList();
    if (data) {
      list[id] = { type, data };
    } else {
      delete list[id];
    }
    const jsonText = JSON.stringify({ version: 1, data: list });
    localStorage.setItem('editingData', jsonText);
  },
  /** 指定されたIDで保存されている編集中データを削除する。 */
  deleteEditingData: function (id: number) {
    return mtpadmdb.saveEditingData(id);
  }
};

export const constData: {
  title: string;
  navis: { text: string; to: RouteLocationRaw }[];
  monsterClearData: EditingMonsterData;
  booleanTable: { [key: number]: string, null: string };
  typeTable: { [key: string]: { name: string; senzaiKiller: number[] }, null: { name: string; senzaiKiller: number[] } };
  attributeTable: { [key: number]: string, null: string };
  attrColors: { [key: number]: string, null: string};
  evolutionTypeTable: { [key: number]: string, null: string};
  evolutionTypeSortTable: (number | null)[];
  awakenTable: {
    [key: number]: { name: string; description: string; value?: number; rate?: number; probability?: number; rate2?: number; base?: { no: number; count: number } }
    null: { name: string; description: string }
  };
  rareStoneExchangeTable: { [key: number]: number[] };
} = {
  title: 'みんなで作るパズドラモンスターデータベース',

  /** ナビゲーションメニュー用データ */
  navis: [
    { text: 'モンスター一覧', to: { name: 'monsterList' }},
    { text: 'スキル一覧', to: { name: 'skillList' }},
    { text: 'リーダースキル一覧', to: { name: 'leaderSkillList' }},
    { text: 'モンスター比較', to: { name: 'compare' }},
    { text: 'ランキング', to: { name: 'ranking' }},
    { text: '新規登録', to: { name: 'monsterEdit' }},
    { text: '画像投稿', to: { name: 'monsterPicture' }},
    { text: 'これは何？', to: { name: 'about' }}
  ],

  /** モンスター情報の空データ */
  monsterClearData: {
    no: null,
    name: '',
    attributes: [null, 0],
    cost: null,
    rare: null,
    types: [null, 0, 0],
    awakens: [null, 0, 0, 0, 0, 0, 0, 0, 0],
    maxExp: null,
    maxLevel: null,
    maxParam: {
      hp: null,
      attack: null,
      recovery: null
    },
    skill: 0,
    skillDetails: {
      name: '',
      description: '',
      baseTurn: null,
      minTurn: null
    },
    leaderSkill: 0,
    leaderSkillDetails: {
      name: '',
      description: ''
    },
    assist: null,
    overLimit: null,
    overLimitParam: {
      hp: null,
      attack: null,
      recovery: null
    },
    superAwakens: [null],
    synchroAwaken: null,
    evolutionType: null,
    evolution: {
      baseNo: null,
      materials: [
        null, null, null, null, null
      ]
    },
    canUnlockExtraSlot: 0,
    comment: ''
  },

  booleanTable: {
    0: '×',
    1: '○',

    null: '不明'
  },

  typeTable: {
    0: { name: 'なし', senzaiKiller: [] },
    1: { name: '神', senzaiKiller: [3, 9, 10, 11, 12] },
    2: { name: 'ドラゴン', senzaiKiller: [4, 9, 10, 11, 12] },
    3: { name: '悪魔', senzaiKiller: [1, 9, 10, 11, 12] },
    4: { name: 'マシン', senzaiKiller: [1, 6, 9, 10, 11, 12] },
    5: { name: 'バランス', senzaiKiller: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
    6: { name: '攻撃', senzaiKiller: [3, 7, 9, 10, 11, 12] },
    7: { name: '体力', senzaiKiller: [4, 8, 9, 10, 11, 12] },
    8: { name: '回復', senzaiKiller: [2, 6, 9, 10, 11, 12] },
    9: { name: '進化用', senzaiKiller: [] },
    10: { name: '能力覚醒用', senzaiKiller: [] },
    11: { name: '強化合成用', senzaiKiller: [] },
    12: { name: '売却用', senzaiKiller: [] },

    null: { name: '不明', senzaiKiller: [] }
  },

  attributeTable: {
    0: 'なし',
    1: '火',
    2: '水',
    3: '木',
    4: '光',
    5: '闇',
    null: '不明'
  },

  attrColors: {
    0: 'rgba(0,0,0,0)',
    1: 'rgba(255, 0, 0, 1.0)',
    2: 'rgba(0, 192, 255, 1.0)',
    3: 'rgba(0, 224, 0, 1.0)',
    4: 'rgba(255, 255, 0, 1.0)',
    5: 'rgba(192, 0, 192, 1.0)',

    null: 'rgba(192, 192, 192, 1.0)'
  },

  evolutionTypeTable: {
    0: '進化なし',
    1: '通常進化',
    2: '究極進化',
    3: '転生進化',
    4: 'ドット進化',
    5: 'アシスト進化',
    6: '超転生進化',
    7: '超究極進化',
    8: '試練進化',

    null: '進化形態不明'
  },

  /** 進化形態の並べる際の並び順を evolutionTypeTable の key で記したもの。 */
  evolutionTypeSortTable: [0, 1, 2, 7, 3, 6, 4, 5, 8, null],

  awakenTable: {
    0: { name: 'なし', description: '' },
    1: { name: 'HP強化', description: 'HPが500アップする', value: 500 },
    2: { name: '攻撃強化', description: '攻撃力が100アップする', value: 100 },
    3: { name: '回復強化', description: '回復力が200アップする', value: 200 },
    4: { name: '火ダメージ軽減', description: '火属性の敵から\n受けるダメージを軽減する', rate: 0.07 },
    5: { name: '水ダメージ軽減', description: '水属性の敵から\n受けるダメージを軽減する', rate: 0.07 },
    6: { name: '木ダメージ軽減', description: '木属性の敵から\n受けるダメージを軽減する', rate: 0.07 },
    7: { name: '光ダメージ軽減', description: '光属性の敵から\n受けるダメージを軽減する', rate: 0.07 },
    8: { name: '闇ダメージ軽減', description: '闇属性の敵から\n受けるダメージを軽減する', rate: 0.07 },
    9: { name: '自動回復', description: 'ドロップを消したターン、\nHPが1000回復する', value: 1000 },
    10: { name: 'バインド耐性', description: '自分自身へのバインド攻撃を\n無効化することがある', probability: 0.5 },
    11: { name: '暗闇耐性', description: '暗闇攻撃を無効化することがある', probability: 0.2 },
    12: { name: 'お邪魔耐性', description: 'お邪魔攻撃や爆弾攻撃を\n無効化することがある', probability: 0.2 },
    13: { name: '毒耐性', description: '毒攻撃を無効化することがある', probability: 0.2 },
    14: { name: '火ドロップ強化', description: '強化された火ドロップの出現率と\nダメージがアップする', probability: 0.2, rate: 0.07 },
    15: { name: '水ドロップ強化', description: '強化された水ドロップの出現率と\nダメージがアップする', probability: 0.2, rate: 0.07 },
    16: { name: '木ドロップ強化', description: '強化された木ドロップの出現率と\nダメージがアップする', probability: 0.2, rate: 0.07 },
    17: { name: '光ドロップ強化', description: '強化された光ドロップの出現率と\nダメージがアップする', probability: 0.2, rate: 0.07 },
    18: { name: '闇ドロップ強化', description: '強化された闇ドロップの出現率と\nダメージがアップする', probability: 0.2, rate: 0.07 },
    19: { name: '操作時間延長', description: 'ドロップ操作時間が少し延びる', value: 0.5 },
    20: { name: 'バインド回復', description: '回復ドロップを横一列でそろえて消すと\n攻撃力が2倍、バインド状態が6ターン回復する\n(この覚醒スキルは覚醒無効の影響を受けない)', rate: 2, value: 6 },
    21: { name: 'スキルブースト', description: 'チーム全体のスキルが\n1ターン溜まった状態で始まる', value: 1 },
    22: { name: '火列強化', description: '火ドロップを横一列でそろえて消すと\n火属性の攻撃力がアップする', rate: 0.3 },
    23: { name: '水列強化', description: '水ドロップを横一列でそろえて消すと\n水属性の攻撃力がアップする', rate: 0.3 },
    24: { name: '木列強化', description: '木ドロップを横一列でそろえて消すと\n木属性の攻撃力がアップする', rate: 0.3 },
    25: { name: '光列強化', description: '光ドロップを横一列でそろえて消すと\n光属性の攻撃力がアップする', rate: 0.3 },
    26: { name: '闇列強化', description: '闇ドロップを横一列でそろえて消すと\n闇属性の攻撃力がアップする', rate: 0.3 },
    27: { name: '2体攻撃', description: '自分と同じ属性のドロップを4個消すと\n攻撃力がアップし、敵2体に攻撃する', rate: 2.2 },
    28: { name: '封印耐性', description: 'スキル封印攻撃を無効化することがある', probability: 0.2 },
    29: { name: '回復ドロップ強化', description: '強化された回復ドロップの出現率と\n回復力がアップする\n回復の4個消しで回復力がアップする', probability: 0.2, rate: 1.5, rate2: 0.05 },
    30: { name: 'マルチブースト', description: '協力プレイ時に\n自分の全パラメータがアップする', rate: 1.5 },
    31: { name: 'ドラゴンキラー', description: 'ドラゴンタイプの敵に対して\n攻撃力が5倍になる\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 5 },
    32: { name: '神キラー', description: '神タイプの敵に対して\n攻撃力が5倍になる\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 5 },
    33: { name: '悪魔キラー', description: '悪魔タイプの敵に対して\n攻撃力が5倍になる\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 5 },
    34: { name: 'マシンキラー', description: 'マシンタイプの敵に対して\n攻撃力が5倍になる\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 5 },
    35: { name: 'バランスキラー', description: 'バランスタイプの敵に対して\n攻撃力が5倍になる\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 5 },
    36: { name: '攻撃キラー', description: '攻撃タイプの敵に対して\n攻撃力が5倍になる\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 5 },
    37: { name: '体力キラー', description: '体力タイプの敵に対して\n攻撃力が5倍になる\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 5 },
    38: { name: '回復キラー', description: '回復タイプの敵に対して\n攻撃力が5倍になる\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 5 },
    39: { name: '進化用キラー', description: '進化用タイプの敵に対して\n攻撃力がアップする\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 3 },
    40: { name: '能力覚醒用キラー', description: '能力覚醒用タイプの敵に対して\n攻撃力がアップする\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 3 },
    41: { name: '強化合成用キラー', description: '強化合成用タイプの敵に対して\n攻撃力がアップする\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 3 },
    42: { name: '売却用キラー', description: '売却用タイプの敵に対して\n攻撃力がアップする\nこの覚醒が発動した敵に対して\nダメージの上限値(カンスト値)が\n1.2倍になる', rate: 3 },
    43: { name: 'コンボ強化', description: '7コンボ以上で攻撃力が2倍になる', rate: 2 },
    44: { name: 'ガードブレイク', description: '5色同時攻撃すると攻撃力が3倍になり、\n敵の防御力を無視してダメージを与える', rate: 3 },
    45: { name: '追加攻撃', description: '回復ドロップを縦一列でそろえて消すと\n固定50万ダメージ\nシールドに20%のダメージ', value: 500000 },
    46: { name: 'チームHP強化', description: 'チームのHPが5％アップする', rate: 0.05 },
    47: { name: 'チーム回復強化', description: 'チームの回復力が20％アップする', rate: 0.2 },
    48: { name: 'ダメージ無効貫通', description: '自分と同じ属性のドロップを3×3の正方形で消すと\n攻撃力が3.5倍になり、ダメージ無効を貫通する', rate: 3.5 },
    49: { name: '覚醒アシスト', description: '他のモンスターにアシストすると\n自分の覚醒スキルが付与される' },
    50: { name: '超追加攻撃', description: '回復ドロップを3×3の正方形で消すと\n攻撃力が8倍になり、固定1000万ダメージ\nシールドに50%のダメージ', value: 10000000, rate: 8 },
    51: { name: 'スキルチャージ', description: '5色同時攻撃すると\n自分のスキルが1ターン貯まる', value: 1 },
    52: { name: 'バインド耐性+', description: '自分自身へのバインド攻撃を\n無効化する', probability: 1, base: { no: 10, count: 2 } },
    53: { name: '操作時間延長+', description: 'ドロップ操作時間が延びる', value: 1, base: { no: 19, count: 2 } },
    54: { name: '雲耐性', description: '雲攻撃を無効化する', probability: 1 },
    55: { name: '操作不可耐性', description: '操作不能攻撃を無効化する', probability: 1 },
    56: { name: 'スキルブースト+', description: 'チーム全体のスキルが\n2ターン溜まった状態で始まる', value: 2, base: { no: 21, count: 2 } },
    57: { name: 'HP50%以上強化', description: 'HP50%以上で攻撃力がアップする', rate: 2.5 },
    58: { name: 'HP50%以下強化', description: 'HP50%以下で攻撃力がアップする', rate: 2.5 },
    59: { name: '回復L字消し', description: '回復ドロップ5個をL字型に消すと\n敵から受けるダメージを軽減し、\n攻撃力が3倍になる\nさらにバインド状態が１ターン回復する', rate: 0.05, rate2: 3 },
    60: { name: 'L字消し攻撃', description: '自分と同じ属性のドロップ5個を\nL字型に消すと攻撃力がアップし、\nロック状態を解除する', rate: 2.2 },
    61: { name: '超コンボ強化', description: '10コンボ以上で攻撃力が5倍になる', rate: 5 },
    62: { name: 'コンボドロップ生成', description: '自分と同じ属性のドロップを10個以上つなげて消すと\nコンボドロップが落ちてくる(最大8個まで)\nさらに1コンボ加算(最大4コンボまで)' },
    63: { name: 'スキルボイス', description: '全パラメータが10%アップする\nスキル使用時に声が出る\n(この覚醒スキルは覚醒無効の影響を受けない)', rate: 1.1 },
    64: { name: 'ダンジョンボーナス', description: '1人プレイの時にランク経験値、モンスター経験値、\n入手コイン、卵ドロップ率がほんの少し上昇' },
    65: { name: 'HP弱化', description: 'HPが2500ダウンする(最小1まで)', value: -2500 },
    66: { name: '攻撃弱化', description: '攻撃力が1000ダウンする(最小1まで)', value: -1000 },
    67: { name: '回復弱化', description: '回復力が2000ダウンする', value: -2000 },
    68: { name: '暗闇耐性+', description: '暗闇攻撃を無効化する', probability: 1, base: { no: 11, count: 5 } },
    69: { name: 'お邪魔耐性+', description: 'お邪魔攻撃や爆弾攻撃を\n無効化する', probability: 1, base: { no: 12, count: 5 } },
    70: { name: '毒耐性+', description: '毒攻撃を無効化する', probability: 1, base: { no: 13, count: 5 } },
    71: { name: 'お邪魔ドロップの加護', description: 'お邪魔ドロップが落ちてくるようになり、\nお邪魔ドロップを消すと攻撃力がアップする', rate: 2, },
    72: { name: '毒ドロップの加護', description: '毒ドロップが落ちてくるようになり、\n毒か猛毒ドロップを消すと攻撃力がアップする', rate: 2 },
    73: { name: '火コンボ強化', description: '火ドロップで2コンボ以上すると、\n火属性の攻撃力がアップする', rate: 1.3 },
    74: { name: '水コンボ強化', description: '水ドロップで2コンボ以上すると、\n水属性の攻撃力がアップする', rate: 1.3 },
    75: { name: '木コンボ強化', description: '木ドロップで2コンボ以上すると、\n木属性の攻撃力がアップする', rate: 1.3 },
    76: { name: '光コンボ強化', description: '光ドロップで2コンボ以上すると、\n光属性の攻撃力がアップする', rate: 1.3 },
    77: { name: '闇コンボ強化', description: '闇ドロップで2コンボ以上すると、\n闇属性の攻撃力がアップする', rate: 1.3 },
    78: { name: '十字消し攻撃', description: '自分と同じ属性のドロップ5個を十字型に消すと\n攻撃力が3倍になり、超暗闇目覚めを3ターン回復する', rate: 3 },
    79: { name: '3色攻撃強化', description: '3色以上同時攻撃で攻撃力がアップする', rate: 2.5 },
    80: { name: '4色攻撃強化', description: '4色以上同時攻撃で攻撃力が4倍になる', rate: 4 },
    81: { name: '5色攻撃強化', description: '5色同時攻撃で攻撃力が5倍になる', rate: 5 },
    82: { name: '超つなげ消し強化', description: '自分と同じ属性のドロップを12個以上つなげて消すと、攻撃力が12倍になる', rate: 12 },
    83: { name: 'ドラゴンタイプ追加', description: 'ダンジョン潜入中、ドラゴンタイプが追加される' },
    84: { name: '神タイプ追加', description: 'ダンジョン潜入中、神タイプが追加される' },
    85: { name: '悪魔タイプ追加', description: 'ダンジョン潜入中、悪魔タイプが追加される' },
    86: { name: 'マシンタイプ追加', description: 'ダンジョン潜入中、マシンタイプが追加される' },
    87: { name: 'バランスタイプ追加', description: 'ダンジョン潜入中、バランスタイプが追加される' },
    88: { name: '攻撃タイプ追加', description: 'ダンジョン潜入中、攻撃タイプが追加される' },
    89: { name: '体力タイプ追加', description: 'ダンジョン潜入中、体力タイプが追加される' },
    90: { name: '回復タイプ追加', description: 'ダンジョン潜入中、回復タイプが追加される' },
    91: { name: '副属性変更・火', description: 'ダンジョン潜入中、副属性が火属性に変更される\n(ダメージは攻撃力の15％分)' },
    92: { name: '副属性変更・水', description: 'ダンジョン潜入中、副属性が水属性に変更される\n(ダメージは攻撃力の15％分)' },
    93: { name: '副属性変更・木', description: 'ダンジョン潜入中、副属性が木属性に変更される\n(ダメージは攻撃力の15％分)' },
    94: { name: '副属性変更・光', description: 'ダンジョン潜入中、副属性が光属性に変更される\n(ダメージは攻撃力の15％分)' },
    95: { name: '副属性変更・闇', description: 'ダンジョン潜入中、副属性が闇属性に変更される\n(ダメージは攻撃力の15％分)' },
    96: { name: '2体攻撃＋', description: '自分と同じ属性のドロップを4個消すと\n攻撃力がかなりアップし、敵2体に攻撃をする\n(2体攻撃2個分の効果)', rate: 4.84, base: { no: 27, count: 2 } },
    97: { name: 'スキルチャージ＋', description: '5色同時攻撃すると\n自分のスキルが2ターン溜まる', value: 2, base: { no: 51, count: 2 } },
    98: { name: '自動回復＋', description: 'ドロップを消したターン、\nHPが2000回復する', value: 2000, base: { no: 9, count: 2 } },
    99: { name: '火ドロップ強化＋', description: '強化された火ドロップの出現率と\nダメージがかなりアップする\n(火ドロップ強化2個分の効果)', probability: 0.4, rate: 0.14, base: { no: 14, count: 2 } },
    100: { name: '水ドロップ強化＋', description: '強化された水ドロップの出現率と\nダメージがかなりアップする\n(水ドロップ強化2個分の効果)', probability: 0.4, rate: 0.14, base: { no: 15, count: 2 } },
    101: { name: '木ドロップ強化＋', description: '強化された木ドロップの出現率と\nダメージがかなりアップする\n(木ドロップ強化2個分の効果)', probability: 0.4, rate: 0.14, base: { no: 16, count: 2 } },
    102: { name: '光ドロップ強化＋', description: '強化された光ドロップの出現率と\nダメージがかなりアップする\n(光ドロップ強化2個分の効果)', probability: 0.4, rate: 0.14, base: { no: 17, count: 2 } },
    103: { name: '闇ドロップ強化＋', description: '強化された闇ドロップの出現率と\nダメージがかなりアップする\n(闇ドロップ強化2個分の効果)', probability: 0.4, rate: 0.14, base: { no: 18, count: 2 } },
    104: { name: '回復ドロップ強化＋', description: '強化された回復ドロップの出現率と\n回復力がかなりアップする\n回復の4個消しで回復力がアップする\n(回復ドロップ強化2個分の効果)', probability: 0.4, rate: 2.25, rate2: 0.1, base: { no: 29, count: 2 } },
    105: { name: 'スキルブーストマイナス', description: 'チーム全体のスキルが\n1ターン減った状態で始まる', value: -1 },
    106: { name: '浮遊', description: '自分自身への超重力効果を緩和する', rate: 20 },
    107: { name: 'コンボ強化+', description: '7コンボ以上で攻撃力が4倍になる\n(コンボ強化2個分の効果)', rate: 4, base: { no: 43, count: 2 } },
    108: { name: 'L字消し攻撃+', description: '自分と同じ属性のドロップ5個を\nL字型に消すと攻撃力がかなりアップし、\nロック状態を解除する\n(L字消し攻撃2個分の効果)', rate: 4.84, base: { no: 60, count: 2 } },
    109: { name: 'ダメージ無効貫通＋', description: '自分と同じ属性のドロップを3×3の正方形で消すと\n攻撃力がかなりアップし、ダメージ無効を貫通する\n(ダメージ無効貫通2個分の効果)', rate: 12.25, base: { no: 48, count: 2 } },
    110: { name: '十字消し攻撃＋', description: '自分と同じ属性のドロップ5個を十字型に消すと\n攻撃力が9倍になり、超暗闇目覚めを6ターン回復する\n(十字消し攻撃2個分の効果)', rate: 9, base: { no:78, count: 2 } },
    111: { name: '超コンボ強化＋', description: '10コンボ以上で攻撃力が25倍になる\n(超コンボ強化2個分の効果)', rate: 25, base: { no: 61, count: 2 } },
    112: { name: '3色攻撃強化＋', description: '3色以上同時攻撃で攻撃力がかなりアップする\n(3色同時攻撃2個分の効果)', rate: 6.25, base: { no: 79, count: 2 } },
    113: { name: '4色攻撃強化＋', description: '4色以上同時攻撃で攻撃力が16倍になる\n(4色同時攻撃2個分の効果)', rate: 16, base: { no: 80, count: 2 } },
    114: { name: '5色攻撃強化＋', description: '5色同時攻撃で攻撃力が25倍になる\n(5色同時攻撃2個分の効果)', rate: 25, base: { no: 81, count: 2 } },
    115: { name: 'バインド回復＋', description: '回復ドロップを横一列でそろえて消すと\n攻撃力が4倍、バインド状態が12ターン回復する\n(この覚醒スキルは覚醒無効の影響を受けない)\n(バインド回復2個分の効果)', rate: 2, value: 12, base: { no: 20, count: 2 } },
    116: { name: '火列強化×3', description: '火ドロップを横一列でそろえて消すと\n火属性の攻撃力がかなりアップする\n(火列強化3個分の効果)', rate: 0.9, base: { no: 22, count: 3 } },
    117: { name: '水列強化×3', description: '水ドロップを横一列でそろえて消すと\n水属性の攻撃力がかなりアップする\n(水列強化3個分の効果)', rate: 0.9, base: { no: 23, count: 3 } },
    118: { name: '木列強化×3', description: '木ドロップを横一列でそろえて消すと\n木属性の攻撃力がかなりアップする\n(木列強化3個分の効果)', rate: 0.9, base: { no: 24, count: 3 } },
    119: { name: '光列強化×3', description: '光ドロップを横一列でそろえて消すと\n光属性の攻撃力がかなりアップする\n(光列強化3個分の効果)', rate: 0.9, base: { no: 25, count: 3 } },
    120: { name: '闇列強化×3', description: '闇ドロップを横一列でそろえて消すと\n闇属性の攻撃力がかなりアップする\n(闇列強化3個分の効果)', rate: 0.9, base: { no: 26, count: 3 } },
    121: { name: '火コンボ強化＋', description: '火ドロップで2コンボ以上すると、\n火属性の攻撃力がかなりアップする\n(火コンボ強化2個分の効果)', rate: 1.6, base: { no: 73, count: 2 } },
    122: { name: '水コンボ強化＋', description: '水ドロップで2コンボ以上すると、\n水属性の攻撃力がかなりアップする\n(水コンボ強化2個分の効果)', rate: 1.6, base: { no: 74, count: 2 } },
    123: { name: '木コンボ強化＋', description: '木ドロップで2コンボ以上すると、\n木属性の攻撃力がかなりアップする\n(木コンボ強化2個分の効果)', rate: 1.6, base: { no: 75, count: 2 } },
    124: { name: '光コンボ強化＋', description: '光ドロップで2コンボ以上すると、\n光属性の攻撃力がかなりアップする\n(光コンボ強化2個分の効果)', rate: 1.6, base: { no: 76, count: 2 } },
    125: { name: '闇コンボ強化＋', description: '闇ドロップで2コンボ以上すると、\n闇属性の攻撃力がかなりアップする\n(闇コンボ強化2個分の効果)', rate: 1.6, base: { no: 77, count: 2 } },
    126: { name: 'Ｔ字消し攻撃', description: '自分と同じ属性のドロップ5個を\nT字型に消すと攻撃力が8倍になり、\nドロップ強化をした状態で攻撃する', rate: 8 },
    127: { name: '全パラメータ強化', description: '自分の全パラメータがアップする', rate: 1.5 },
    128: { name: '陽の加護', description: 'の表示があるフロアでHPと回復力が1.2倍、攻撃力は5倍になる', rate: 5, rate2: 1.2 },
    129: { name: '陰の加護', description: 'の表示があるフロアでHPと回復力が1.2倍、攻撃力は5倍になる', rate: 5, rate2: 1.2 },
    130: { name: '熟成', description: 'バトル5以降で全パラメータが1.5倍、\nバトル10以降で全パラメータが2倍になる', rate: 1.5, rate2: 2 },
    131: { name: '部位破壊ボーナス', description: '部位破壊素材の\nドロップ率が10％上昇\n部位を破壊するたびに\n全パラメータが1.2倍になる', rate: 0.1, rate2: 1.2 },

    null: { name: '不明', description: '' }
  },

  rareStoneExchangeTable: {
    // 希石【小】
    4453: [
      142, 216, 283, 441, 540,
      782, 974, 1166, 1591, 1761,
      1957, 2333, 2429, 2575, 2577,
      2642, 2647
    ],
    4454: [
      143, 217, 284, 442, 541,
      783, 975, 1167, 1592, 1762,
      1958, 2334, 2430, 2579, 2643,
      2648
    ],
    4455: [
      144, 218, 285, 443, 542,
      784, 976, 1168, 1593, 1763,
      1959, 2336, 2431, 2576, 2644,
      2649
    ],
    4456: [
      145, 219, 286, 444, 543,
      785, 977, 1169, 1594, 1764,
      1960, 2335, 2337, 2434, 2578,
      2645, 2650
    ],
    4457: [
      146, 220, 287, 445, 544,
      786, 978, 1170, 1595, 1765,
      1961, 2338, 2432, 2433, 2580,
      2646, 2651
    ],
    // 希石【中】
    4458: [
      408, 566, 599, 772, 1252,
      1590, 2875, 3000, 3207, 3721,
      3835
    ],
    4459: [
      409, 597, 773, 1307, 1461,
      1532, 2876, 3001, 3011, 3208,
      3722, 3836
    ],
    4460: [
      410, 651, 774, 778, 1098,
      1189, 1190, 1223, 1425, 2877,
      3002, 3209, 3723, 3837
    ],
    4461: [
      188, 226, 411, 649, 775,
      810, 812, 1250, 1463, 2878,
      2890, 3012, 3210, 3724, 3838
    ],
    4462: [
      190, 412, 645, 647, 776,
      814, 1062, 1465, 1648, 2879,
      3211, 3725, 3839
    ],
    // 希石【大】
    4463: [
      1750, 2008, 2184, 2398, 2722,
      1225, 1272
    ],
    4464: [
      1227, 1273, 1752, 2104, 2182,
      2263, 2383
    ],
    4465: [
      1274, 1472, 1629, 1711, 1754,
      1837, 1945, 2069, 2129, 2320,
      2549
    ],
    4466: [
      917, 1119, 1246, 1510, 1525,
      1631, 2234, 2400, 2524, 2551
    ],
    4467: [
      918, 985, 1215, 1248, 1646,
      2402
    ],
    // 希石【特大】
    7757: [
      4474, 4475, 4484, 5072, 5547,
      6144, 6254, 6350
    ],
    7758: [
      4493, 4494, 4504, 5073, 5122,
      5484, 5638, 6089, 6178, 6878
    ],
    7759: [
      4512, 4525, 5074, 5735, 5754,
      6344, 7004
    ],
    7760: [
      4535, 4536, 4549, 5075, 5174,
      5424, 5963, 6084, 6228, 6522
    ],
    7761: [
      4558, 4559, 4561, 4574, 5076,
      5320, 6085, 7271, 7493
    ]
  }
};

/** 空のモンスター情報を取得する。 */
export function getMonsterClearData(): EditingMonsterData {
  return JSON.parse(JSON.stringify(constData.monsterClearData));
};

export const commonData: {
  lastModified: { [key: string]: string };
  monsterData: EditingMonsterData;
  monsterTable: { [key: number]: MonsterDataWithAwakenCount };
  skillTable: { [key: number]: SkillDetailsWithNo };
  leaderSkillTable: { [key: number]: SkillDetailsWithNo };
  imageTable: { [key: number]: { id: number } };
  evolutionTable: { [key: number]: EvolutionWithMonsterNo[] };
  monsterFavorites: { [key: number]: number };
  lastLoadCommonDataTime: number;
  messages: string[];
  errors: string[];
  accountData: firebase.User | null;
} = {
  lastModified: {},
  monsterData: getMonsterClearData(),

  monsterTable: {},
  skillTable: {},
  leaderSkillTable: {},
  imageTable: {},
  evolutionTable: {},

  /** モンスターをお気に入りに入れているかの情報 */
  monsterFavorites: {},
  /** commonData のJSONを最後に読み込んだ時間 */
  lastLoadCommonDataTime: 0,

  messages: [],
  errors: [],

  /**
   * 現在のログインアカウントの情報。ログイン情報未確認時は null、
   * 未ログイン時は空オブジェクト、ログイン時はそのアカウントの情報が入ったオブジェクト。
   */
  accountData: null
};

/** 本番環境でのみ gtag を実行する関数 */
export const gtagProductionOnly = (location.port !== '')
  ? function () {}
  : function (command:any, ...args:any[]) {
    if (store.getters.isAdmin) { return; }
    gtag(command, ...args);
  };

/** 数字をカンマ区切りにする。 */
export function addComma (val: number | string) {
  const arr = String(val).split('.');
  arr[0] = arr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  return arr.join('.');
}

/** HTMLエスケープを行う。 */
export function escapeHtml (str: string) {
  if (typeof str !== 'string') { return ''; }
  return str.replace(/[<>&"'`]/g, (match) => {
    const escape: { [before:string]: string } = {
      '<': '&lt;',
      '>': '&gt;',
      '&': '&amp;',
      '"': '&quot;',
      '\'': '&#39;',
      '`': '&#x60;'
    };
    return escape[match];
  });
}

/** HTMLアンエスケープを行う。 */
export function unescapeHtml (str: string) {
  if (typeof str !== 'string') { return ''; }
  return str.replace(/&lt;|&gt;|&amp;|&quot;|&#39;|&#x60;/g, (match) => {
    const unescape: { [before:string]: string } = {
      '&lt;': '<',
      '&gt;': '>',
      '&amp;': '&',
      '&quot;': '"',
      '&#39;': '\'',
      '&#x60;': '`'
    };
    return unescape[match];
  });
}

// 正規表現エスケープを行う関数を作成する。 from https://s8a.jp/javascript-escape-regexp にあるものを若干変更
export let escapeRegExp: Function;
(function () {
  const reRegExp = /[\\^$.*+?()[\]{}|]/g;
  const reHasRegExp = new RegExp(reRegExp.source);

  function _escapeRegExp (string: string) {
    return (string && reHasRegExp.test(string))
      ? string.replace(reRegExp, '\\$&')
      : string;
  }

  escapeRegExp = _escapeRegExp;
})();

/**
 * 検索ワードのひらがなカタカナを、ひらがなとカタカナの両方にヒットする正規表現に変更する。
 * 小文字大文字のある文字の場合はその両方を含める。
 */
function toHiraKanaSearchRegExp (str: string) {
  return str.replace(/[\u3041-\u3096\u30a1-\u30f6]/g, function (match: string) {
    let hiraCode = match.charCodeAt(0);
    if (hiraCode >= 0x30a1) { hiraCode -= 0x60; }
    const kataCode = hiraCode + 0x60;
    const hira = String.fromCharCode(hiraCode);
    const kata = String.fromCharCode(kataCode);
    let resizeChars = '';
    if (/[あいうえおつやゆよわ]/.test(hira)) {
      resizeChars = String.fromCharCode(hiraCode - 1) + String.fromCharCode(kataCode - 1);
    }
    if (/[ぁぃぅぇぉっゃゅょゎ]/.test(hira)) {
      resizeChars = String.fromCharCode(hiraCode + 1) + String.fromCharCode(kataCode + 1);
    }
    return `[${hira}${kata}${resizeChars}]`;
  });
}

/**
 * ば行 と ヴァ行 を相互に検索可能な正規表現に置き換える。
 * ば行 の場合は /ゔ.{1,2}ぁ/ をヒットさせたくないので、文字間に関するあいまい検索置き換えのあとに行う。
 * そのため置き換え元の文字にも .{0,2} が入った状態でチェックする。
 */
function toBaVaAimaiRegExp (str: string) {
  const aimai = '.{0,2}';
  return str.replace(/[ばびぶべぼバビブベボ]|[ゔヴ](?:\.\{0,2\}([ぁあぃいぇえぉおァアィイェエォオ]))?/g, function (match: string, p1: string): string {
    switch (match[0]) {
    case 'ば': case 'バ':
      return '(?:ゔぁ|ば)';
    case 'び': case 'ビ':
      return '(?:ゔぃ|び)';
    case 'ぶ': case 'ブ':
      return '(?:ゔ|ぶ)';
    case 'べ': case 'ベ':
      return '(?:ゔぇ|べ)';
    case 'ぼ': case 'ボ':
      return '(?:ゔぉ|ぼ)';
    case 'ゔ': case 'ヴ':
      switch (p1) {
      case 'ぁ': case 'あ': case 'ァ': case 'ア':
        return `(?:ゔ${aimai}ぁ|ば)`;
      case 'ぃ': case 'い': case 'ィ': case 'イ':
        return `(?:ゔ${aimai}ぃ|び)`;
      case '': case undefined:
        return '(?:ゔ|ぶ)';
      case 'ぇ': case 'え': case 'ェ': case 'エ':
        return `(?:ゔ${aimai}ぇ|べ)`;
      case 'ぉ': case 'お': case 'ォ': case 'オ':
        return `(?:ゔ${aimai}ぉ|ぼ)`;
      }
    }
    return match;
  });
}

/** 検索用に、全角と半角を統一する。
 *  一部の記号は全角に、英数字は半角にする。
 */
export function unificationFullWidthHalfWidth (str: string) {
  const convertTable: { [hankaku:string]: string } = {
    '(': '（',
    ')': '）',
    '+': '＋',
    '\\(': '（',
    '\\)': '）',
    '\\+': '＋',
    '%': '％',
    '&': '&',
    '=': '＝'
  };
  const replaceRegExp = /(?:\\\(|\\\)|\\\+|[()+%&=])/;
  return str.replace(replaceRegExp, function (s: string) {
    return convertTable[s];
  }).replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s: string) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}

/** 指定された文字列を、検索ワードの文字と文字の間が2文字まで空いていてもヒットする検索形式の正規表現に変換する。 */
function toAimaiSpaceSearch (str: string) {
  // 文字と文字の間に .{0,2} を入れる。
  // 正規表現の制御文字（カッコの終了除く）やエスケープ文字の直後と正規表現の制御文字（カッコの開始除く）の直前は除く。
  return str.replace(/(?:[$^.*+?([{|]|\(?:|\(?=)*\\?.(?=[^\\^$.*+?)\]|])/g, '$&.{0,2}');
}

/**
 * 検索ワードをあいまい検索を行うための正規表現に変換する。
 * ２文字以上の間が空いていてもヒットする、『バ』と『ヴァ』の相互ヒット、ひらがか・カタカナの相互ヒットの３種類を適用する。
 */
export function toAimaiSearch (word: string) {
  const temp1 = toAimaiSpaceSearch(word);
  const temp2 = toBaVaAimaiRegExp(temp1);
  return toHiraKanaSearchRegExp(temp2);
}


/** スキル説明文内のアイコンを表示するHTMLを作成する。 */
function createSkillDescriptionIconHtml(src: string, alt: string = '') {
  return `<img style="width: 1em; height: 1em;" src="${src}" alt="${alt}" />`;
}
/** スキル説明文内でドロップアイコンを表示するHTMLを作成する。 */
function createDropIconHtml(no: number, alt: string = '') {
  return createSkillDescriptionIconHtml(getDropIconUrl(no), alt);
}

/** スキル説明文内でタイプアイコンを表示するHTMLを作成する。 */
export function getTypeIconHtml (type: number, alt: string = '') {
  return createSkillDescriptionIconHtml(`./image/type/${type}.png`, alt);
}

/**  */
const skillIconReplaceTable: { [notation:string]: [ (no: number, alt: string) => string, number ] } = {
  '[火]': [ createDropIconHtml, 1],
  '[水]': [ createDropIconHtml, 2],
  '[木]': [ createDropIconHtml, 3],
  '[光]': [ createDropIconHtml, 4],
  '[闇]': [ createDropIconHtml, 5],
  '[回復]': [ createDropIconHtml, 6],
  '[お邪魔]': [ createDropIconHtml, 7],
  '[毒]': [ createDropIconHtml, 8],
  '[猛毒]': [ createDropIconHtml, 9],
  '[爆弾]': [ createDropIconHtml, 10],
  '[ロック]': [ createDropIconHtml, 11],
  '[コンボドロップ]': [ createDropIconHtml, 12],
  '[釘ドロップ]': [ createDropIconHtml, 13],

  '[神タイプ]': [ getTypeIconHtml, 1],
  '[ドラゴンタイプ]': [ getTypeIconHtml, 2],
  '[悪魔タイプ]': [ getTypeIconHtml, 3],
  '[マシンタイプ]': [ getTypeIconHtml, 4],
  '[バランスタイプ]': [ getTypeIconHtml, 5],
  '[攻撃タイプ]': [ getTypeIconHtml, 6],
  '[体力タイプ]': [ getTypeIconHtml, 7],
  '[回復タイプ]': [ getTypeIconHtml, 8],
  '[進化用モンスター]': [ getTypeIconHtml, 9],
  '[能力覚醒用モンスター]': [ getTypeIconHtml, 10],
  '[強化合成用モンスター]': [ getTypeIconHtml, 11],
  '[売却用モンスター]': [ getTypeIconHtml, 12],
};

/** スキル説明文内で覚醒アイコンを表示するHTMLを作成する。 */
function createAwakenIconHtml(no: number, alt: string = '') {
  return createSkillDescriptionIconHtml(getAwakenIconUrl(no), alt);
}
// 覚醒アイコン表示用の置き換え情報を追加。
for (const [key, value] of Object.entries(constData.awakenTable)) {
  skillIconReplaceTable[`[${value.name}]`] = [ createAwakenIconHtml, Number(key) ];
}

// 表記ゆれ対応
skillIconReplaceTable['[HP50％以上強化]'] = skillIconReplaceTable['[HP50%以上強化]'];
skillIconReplaceTable['[HP50％以下強化]'] = skillIconReplaceTable['[HP50%以下強化]']

/** スキル説明文内のアイコン記法を img タグに置換する。 */
function replaceSkillDescriptionIcons(text: string): string {
  return text.replace(/\[.+?\]/g, (match): string => {
    const a = skillIconReplaceTable[match];
    if (!a) { return match; }
    return a[0](a[1], match);
  });
}

/** スキルの効果をゲーム内の表記と同様の表示になるよう装飾した HTML を作成する。 */
export function skillDescriptionToDecoratedHtml (description: string | null) {
  if (typeof description !== 'string') { return description; }
  const escapedDescription = escapeHtml(description);
  const iconReplacedDescription =replaceSkillDescriptionIcons(escapedDescription);
  return iconReplacedDescription.replace(/バトル\d+(?:以前|以降)の場合|^.+?使用可能。|【\d+ターン後に発動】[\s\S]*|スキル使用時、このアシストが消滅する。|盤面に.+が各?\d+個以上ある場合、|【回数制限\d+回】/, '<span style="color:#2944e7">$&</span>');
}

/** リーダースキルの効果をゲーム内の表記と同様の表示になるよう装飾した HTML を作成する。 */
export function leaderSkillDescriptionToDecoratedHtml (description: string | null) {
  if (typeof description !== 'string') { return description; }
  const escapedDescription = escapeHtml(description);
  const iconReplacedDescription = replaceSkillDescriptionIcons(escapedDescription);
  return iconReplacedDescription.replace(/^【.*】|ドロップを\d+個以下で消せない|操作時間が[\d.]+秒短縮。/g, '<span style="color:#ff3600;">$&</span>');
}

/** 現在のURLでの history 形式でのルートを求める */
export function getRouterBase () {
  const routerBaseArray = /^.*padmdb[^/]*\//i.exec(location.pathname);
  if (routerBaseArray) { return routerBaseArray[0]; }
  return '/';
}

/** 強化合成が可能なモンスターかどうかを判定する。 */
export function checkCanMixMonster (monsterData: MonsterData) {
  // 最大レベルが1で素材系のタイプの場合は合成不可と判断する。
  if (monsterData.maxLevel != null && monsterData.maxLevel > 1) { return true; }
  const type = monsterData.types[0];
  return type == null || !((type >= 9 && type <= 12) || type === 99);
}

/** 超限界突破後のLv120時のパラメータを算出する。 */
export function calcSuperOverLimitParam (monsterData: MonsterData) {
  if (!monsterData.overLimit) { return null; }
  const baseParam = monsterData.maxParam;
  const olParam = monsterData.overLimitParam;
  const superOverLimitRates = {
    hp: 0.1,
    attack: 0.05,
    recovery: 0.05
  };
  const parameterKeys = Object.keys(superOverLimitRates);
  const result: { hp: number | null, attack: number | null, recovery: number | null} = { hp: null, attack: null, recovery: null };
  parameterKeys.forEach((key) => {
    if (key !== 'hp' && key !== 'attack' && key !== 'recovery') { return; }
    const base = baseParam[key];
    const overLimit = olParam[key];
    if (base === null || overLimit === null) {
      result[key] = null;
    } else {
      const overLimitRate = (base === 0) ? 0 : Math.round(overLimit / base * 100) / 100;
      result[key] = Math.round(base * (overLimitRate + superOverLimitRates[key]));
    }
  });
  return result;
}

/** 再送を防ぐためために、送信状態をタイムアウト機能付きで記録するクラス。 */
export class MultiSendBlocker {
  /** タイムアウト処理のID。 */
  timeoutId: number = 0;
  /** タイムアウトを行うまでのミリ秒。 */
  timeoutMs;
  /** コンストラクタ。
   * @param {number} timeoutMs - タイムアウトを行うまでのミリ秒。省略時は20000。（=20秒）
   */
  constructor (timeoutMs?: number) {
    this.timeoutMs = timeoutMs || 20 * 1000;
  }
  /** 現在送信中かどうか。 */
  get isSending () { return !!this.timeoutId; }
  /** 送信状態にする。 */
  set () {
    this.reset();
    // 何かしらあってレスポンスが帰ってこなかった場合に再送信できるように指定時間後に復帰させる。
    this.timeoutId = window.setTimeout(() => {
      this.timeoutId = 0;
    }, this.timeoutMs);
  }
  /** 送信状態を解除する。 */
  reset () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = 0;
    }
  }
}

/** 指定された要素の横幅が親要素より大きい場合に、親要素の横幅に収まるように縮小させる。 */
export function stretchElement (elm: HTMLElement) {
  // 子要素を表示していることによって親要素のサイズか変わる可能性があるので、非表示に下状態で親要素のサイズを取得する。
  const tempDisplay = elm.style.display;
  elm.style.display = 'none';
  const parentStyle = getComputedStyle(elm.parentNode as Element);
  const parentWidth = parseFloat(parentStyle.width) - parseFloat(parentStyle.paddingLeft) - parseFloat(parentStyle.paddingRight) -
    parseFloat(parentStyle.borderLeftWidth) - parseFloat(parentStyle.borderRightWidth);
  elm.style.display = tempDisplay;

  elm.style.transform = '';
  const width = elm.scrollWidth;
  if (parentWidth < width) {
    elm.style.transform = 'scaleX(' + (parentWidth / width) + ')';
    elm.style.transformOrigin = 'left';
  }
}

/** パラメータ入力インプットに、Alt/Shift/Commandを同時に押しながら上下を押すことで0.1/10/100ずつ変更する機能を追加する。 */
export function handleParamInputKeyDown(e: KeyboardEvent) {
  const { code, target, metaKey, shiftKey, altKey } = e;

  if (!(code === "ArrowUp" || code === "ArrowDown")) { return; }

  // targetをHTMLInputElementとして扱うための型アサーション
  if (!(target instanceof HTMLInputElement)) { return; }

  const step = metaKey ? 100 : shiftKey ? 10 : altKey ? 0.1 : 1;
  const offset = (code === "ArrowUp") ? step : -step;
  let newValue = Number(target.value) + offset;
  const roundRate = 10000000;
  newValue = Math.round(newValue * roundRate) / roundRate;

  if (target.hasAttribute('min')) { newValue = Math.max(newValue, parseFloat(target.min)); }
  if (target.hasAttribute('max')) { newValue = Math.min(newValue, parseFloat(target.max)); }
  target.value = String(newValue);
  target.dispatchEvent(new InputEvent('input'));

  e.preventDefault();
}
