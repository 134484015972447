import { getAwakenIconUrl } from '@/components/awakenIcon.vue';

const AWAKEN_COUNT = 131;

export interface DefaultParameters {
  coordinates: {
    x: number;
    y: number;
  };
  size: number;
  spacing: number;
}

/**
 * デフォルトのパラメータ設定
 * @typedef {object} DefaultParameters
 * @property {object} coordinates - 開始座標
 * @property {number} coordinates.x - X座標 (%)
 * @property {number} coordinates.y - Y座標 (%)
 * @property {number} size - 切り出しサイズ (%)
 * @property {number} spacing - 間隔 (%)
 */
export const defaultParameters: DefaultParameters = {
  coordinates: {
    x: 92.1,
    y: 10.4,
  },
  size: 5,
  spacing: 2.82,
};

/**
 * 画像からピクセルデータを抽出する
 * @param {CanvasImageSource} image 画像
 * @returns {Number[]} ピクセルデータの配列
 */
function getPixelData(image: CanvasImageSource): number[] {
  const ICON_SIZE = 10;
  const canvas = document.createElement('canvas');
  canvas.width = ICON_SIZE;
  canvas.height = ICON_SIZE;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return []; // ctx が null の場合は空の配列を返す
  }
  ctx.drawImage(image, 0, 0, ICON_SIZE, ICON_SIZE);
  const imageData = ctx.getImageData(1, 1, ICON_SIZE - 2, ICON_SIZE - 2).data;
  const pixelDataArray: number[] = [];
  for (let j = 0; j < imageData.length; j += 4) {
    pixelDataArray.push(imageData[j]);     // R
    pixelDataArray.push(imageData[j + 1]); // G
    pixelDataArray.push(imageData[j + 2]); // B
  }
  return pixelDataArray;
}

/**
 * 覚醒スキルのピクセルデータをキャッシュする変数
 * 初回アクセス時にgetAwakensPixelData関数によってデータが取得され、以降はキャッシュされたデータが使用される
 * @type {number[][] | null}
 */
let cachedAwakensPixelData: number[][] | null = null;

/**
 * 覚醒スキルのピクセルデータを取得する
 * @returns {Promise<number[][]} 覚醒スキルのピクセルデータの配列
 */
async function getAwakensPixelData(): Promise<number[][]> {
  if (cachedAwakensPixelData) {
    return cachedAwakensPixelData;
  }

  const awakensPixelData: number[][] = Array(AWAKEN_COUNT).fill(null).map(() => []);
  for (let i = 1; i < AWAKEN_COUNT; i++) {
    const imageUrl = getAwakenIconUrl(i);
    const img = new Image();
    img.crossOrigin = "anonymous"; // CORSエラーを回避
    img.src = imageUrl;
    try {
      await new Promise<void>((resolve, reject) => {
        img.onload = () => {
          const pixelDataArray = getPixelData(img);
          awakensPixelData[i] = pixelDataArray;
          resolve();
        };
        img.onerror = () => {
          console.error(`Failed to load image: ${imageUrl}`);
          reject();
        };
      });
    } catch (error) {
      console.error(`Error processing image ${imageUrl}:`, error);
    }
  }

  cachedAwakensPixelData = awakensPixelData;
  return awakensPixelData;
}

/**
 * 指定された画像から覚醒スキルの画像を切り出す
 * @param {HTMLImageElement} sourceImage 元画像
 * @param {object} coordinates 切り出し開始座標（%指定）
 * @param {number} size 切り出す画像のサイズ（%指定）
 * @param {number} spacing 画像の間隔（%指定）
 * @returns {object} 切り出した画像のデータURLとピクセルデータの配列
 */
export function extractImages(
  sourceImage: HTMLImageElement,
  coordinates: { x: number; y: number },
  size: number,
  spacing: number
): { dataURLs: string[]; pixelDataArrays: number[][] } {
  if (!sourceImage) return { dataURLs: [], pixelDataArrays: [] };

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return { dataURLs: [], pixelDataArrays: [] }; // ctx が null の場合は空の配列を返す
  }
  const imageWidth = sourceImage.width;
  const imageHeight = sourceImage.height;
  const x = (coordinates.x / 100) * imageWidth;
  const y = (coordinates.y / 100) * imageHeight;
  const sizeVal = (size / 100) * Math.min(imageWidth, imageHeight);
  const spacingVal = (spacing / 100) * Math.min(imageWidth, imageHeight);

  canvas.width = sizeVal;
  canvas.height = sizeVal;

  const dataURLs: string[] = [];
  const pixelDataArrays: number[][] = [];

  for (let i = 0; i < 9; i++) {
    ctx.drawImage(sourceImage, x, y + i * (sizeVal + spacingVal), sizeVal, sizeVal, 0, 0, sizeVal, sizeVal);
    dataURLs.push(canvas.toDataURL());
    const pixelDataArray = getPixelData(canvas);
    pixelDataArrays.push(pixelDataArray);
  }

  return { dataURLs: dataURLs, pixelDataArrays: pixelDataArrays };
}

/**
 * 画像のピクセルデータと覚醒スキルデータを比較し、最も近い覚醒スキルを返す
 * @param {number[]} pixelDataArray 画像のピクセルデータ
 * @param {number[][]} awakensPixelData 覚醒スキルのピクセルデータ
 * @returns {number|null} 最も近い覚醒スキルのID、見つからない場合はnull
 */
function findNearestAwaken(pixelDataArray: number[], awakensPixelData: number[][]): number | null {
  let minDiff = Infinity;
  let matchedAwaken: number | null = null;
  for (let i = 1; i < AWAKEN_COUNT; i++) {
    if (!awakensPixelData[i]) continue;
    let diff = 0;
    for (let j = 0; j < pixelDataArray.length; j++) {
      diff += Math.abs(pixelDataArray[j] - awakensPixelData[i][j]);
    }
    if (diff < minDiff) {
      minDiff = diff;
      matchedAwaken = i;
    }
  }
  // 類似度が一定以上離れている場合はnullを返す
  if (matchedAwaken !== null && minDiff > 7500) { // 閾値は調整が必要
    matchedAwaken = null;
  }
  return matchedAwaken;
}

/**
 * 貼られた画像から覚醒部分を取り出し、それを覚醒画像と比較する処理
 * @param {HTMLImageElement} sourceImage 元画像
 * @param {object} coordinates 切り出し開始座標（%指定）
 * @param {number} size 切り出す画像のサイズ（%指定）
 * @param {number} spacing 画像の間隔（%指定）
 * @returns {Promise<(number | null)[]>} マッチした覚醒スキルのIDの配列
 */
export async function processAwakenMatching(
  sourceImage: HTMLImageElement,
  coordinates: { x: number; y: number } = defaultParameters.coordinates,
  size: number = defaultParameters.size,
  spacing: number = defaultParameters.spacing
): Promise<(number | null)[]> {
  const { pixelDataArrays } = extractImages(sourceImage, coordinates, size, spacing);
  const awakenPixelData = await getAwakensPixelData();
  const matchedAwakens = pixelDataArrays.map((pixelData) =>
    findNearestAwaken(pixelData, awakenPixelData)
  );
  return matchedAwakens;
}
