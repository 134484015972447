<template>
  <button @click="share">
    <!-- デフォルトシェアアイコン（SVG） -->
    <svg v-if="!isAppleDevice" class="default-icon" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="-2 -4 28 34" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <circle cx="18" cy="5" r="3"></circle>
      <circle cx="6" cy="12" r="3"></circle>
      <circle cx="18" cy="19" r="3"></circle>
      <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
      <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
    </svg>
    <!-- iOS/macOSシェアアイコン（SVG） -->
    <svg v-if="isAppleDevice" class="apple-icon" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="-2 -4 28 34" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M4 10v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
      <polyline points="16 6 12 2 8 6"></polyline>
      <line x1="12" y1="2" x2="12" y2="13"></line>
    </svg>シェア
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/** ツイートボタン表示のコンポーネント */
export default defineComponent({
  name: 'TweetButton',
  props: {
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
    hashtags: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      id: 'tweetButton' + (Math.random() * 1000000).toFixed(0),
      encodedTitle: '',
      encodedUrl: '',
      isAppleDevice: /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)
    };
  },
  computed: {
    encodedHashtags: function () {
      let hashtags = 'パズドラ,mtPADmDB';
      if (this.hashtags) { hashtags += ',' + this.hashtags; }
      return encodeURIComponent(hashtags);
    }
  },
  methods: {
    share: function () {
      // Web Share API を使用して共有
      if (navigator.share) {
        navigator.share({
          title: document.title,
          text: '', // 必要に応じてテキストを追加
          url: document.location.href,
        })
          .then(() => console.log('共有成功'))
          .catch((error) => console.log('共有失敗', error));
      } else {
        // Web Share API が利用できない場合のフォールバック処理
        alert('このブラウザでは共有機能が利用できません。');
      }
    }
  }
});
</script>

<style scoped>
  button {
    display: inline-block;
    color: #FFFFFF;
    text-decoration: none;
    background: #1DA1F2;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    margin: 2px;
    padding: 2px 4px 0px 0px;
  }
</style>
